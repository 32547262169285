html,
body {
  padding: 0;
  margin: 0;
  max-width: 100%;
  overflow-x: hidden;
  font-family: Inter, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

body {
  background-color: #fff;
}