// /** Used to define container behavior: width, position: fixed etc... **/
// .Toastify__toast-container {
// }

// /** Used to define the position of the ToastContainer **/
// .Toastify__toast-container--top-left {
// }
// .Toastify__toast-container--top-center {
// }

.Toastify__toast-container--top-right {
    @media only screen and (max-width: 480px) {
        margin-top: 100px !important;
    }
}
// .Toastify__toast-container--bottom-left {
// }
// .Toastify__toast-container--bottom-center {
// }
// .Toastify__toast-container--bottom-right {
// }

/** Classes for the displayed toast **/
.Toastify__toast {
    @media only screen and (max-width: 480px) {
        max-width: 400px;
        margin-left: 5px;
        margin-right: 5px;
    }
}
// .Toastify__toast--rtl {
// }
.Toastify__toast-body {
    align-items: flex-start !important;
}

/** Used to position the icon **/
.Toastify__toast-icon {
    margin-top: 3px !important;
}

/** handle the notification color and the text color based on the theme **/
// .Toastify__toast-theme--dark {
// }
// .Toastify__toast-theme--light {
// }
// .Toastify__toast-theme--colored.Toastify__toast--default {
// }
.Toastify__toast-theme--colored.Toastify__toast--info {
    background-color: #eefeff !important;
    color: #178396 !important;
    border-radius: 16px;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
}
.Toastify__toast-theme--colored.Toastify__toast--success {
    background-color: #f2ffee !important;
    color: #179668 !important;
    border-radius: 16px;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
}
.Toastify__toast-theme--colored.Toastify__toast--warning {
    background-color: #ffffee !important;
    color: #cdcd22 !important;
    border-radius: 16px;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
}
.Toastify__toast-theme--colored.Toastify__toast--error {
    background-color: #FFEEEE !important;
    color: #FF0000 !important;
    border-radius: 16px;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
}

// .Toastify__progress-bar {
// }
// .Toastify__progress-bar--rtl {
// }
// .Toastify__progress-bar-theme--light {
// }
// .Toastify__progress-bar-theme--dark {
// }
// .Toastify__progress-bar--info {
// }
// .Toastify__progress-bar--success {
// }
// .Toastify__progress-bar--warning {
// }
// .Toastify__progress-bar--error {
// }
// /** colored notifications share the same progress bar color **/
// .Toastify__progress-bar-theme--colored.Toastify__progress-bar--info,
// .Toastify__progress-bar-theme--colored.Toastify__progress-bar--success,
// .Toastify__progress-bar-theme--colored.Toastify__progress-bar--warning,
// .Toastify__progress-bar-theme--colored.Toastify__progress-bar--error {
// }

/** Classes for the close button. Better use your own closeButton **/
.Toastify__close-button {
    color: #484E54 !important;
}
// .Toastify__close-button--default {
// }
// .Toastify__close-button > svg {
// }
// .Toastify__close-button:hover,
// .Toastify__close-button:focus {
// }