.c-input {
  @apply input bg-base-300 mt-1 focus:outline-primary block w-full shadow-sm sm:text-sm rounded-md;
}

.c-section-title {
  @apply pb-2 mb-4 border-b border-base-content font-bold;
}

body {
  background-repeat: no-repeat !important;
  background: white;
  min-height: 100vh;
  margin: 0;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.backdrop-box {
  background: rgba(255, 255, 255, 0.5);
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(25px);

  border-radius: 32px;
}

.tooltip-top-align-daikin::before {
  left: -300%;
}

.modal-daikin {
  background-color: rgba(0, 0, 0, 25%) !important;
}

:root {
  --daikin: 255 100% 100%;
  --daikinc: 198 100% 45%;
}

.checkbox-daikin:checked,
.checkbox-daikin[checked="true"],
.checkbox-daikin[aria-checked="true"] {
  --tw-border-opacity: 1;
  border-color: hsl(var(--daikinc) / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: hsl(var(--daikin) / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: hsl(var(--erc, var(--daikinc)) / var(--tw-text-opacity));
  opacity: 1;
  background-image: linear-gradient(
      -45deg,
      transparent 65%,
      hsl(var(--chkbg)) 65.99%
    ),
    linear-gradient(45deg, transparent 75%, hsl(var(--chkbg)) 75.99%),
    linear-gradient(-45deg, hsl(var(--chkbg)) 40%, transparent 40.99%),
    linear-gradient(
      45deg,
      hsl(var(--chkbg)) 30%,
      hsl(var(--chkfg)) 30.99%,
      hsl(var(--chkfg)) 40%,
      transparent 40.99%
    ),
    linear-gradient(-45deg, hsl(var(--chkfg)) 50%, hsl(var(--chkbg)) 50.99%);
}

.checkbox-daikin {
  --chkbg: var(--daikin);
  --chkfg: var(--daikinc);
  --tw-border-opacity: 1;
  border-color: hsl(var(--daikinc) / var(--tw-border-opacity));
  opacity: 0.2;
  background-image: linear-gradient(
      -45deg,
      transparent 65%,
      hsl(var(--chkbg)) 65.99%
    ),
    linear-gradient(45deg, transparent 75%, hsl(var(--chkbg)) 75.99%),
    linear-gradient(-45deg, hsl(var(--chkbg)) 40%, transparent 40.99%),
    linear-gradient(
      45deg,
      hsl(var(--chkbg)) 30%,
      hsl(var(--chkfg)) 30.99%,
      hsl(var(--chkfg)) 40%,
      transparent 40.99%
    ),
    linear-gradient(-45deg, hsl(var(--chkfg)) 50%, hsl(var(--chkbg)) 50.99%);
}

/* For Webkit-based browsers (Chrome, Safari and Opera) */
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
