.carddetail {
    box-sizing: border-box;
    width: 1049px;
    height: relative;

    background: #FFFFFF;
    border-radius: 32px;

    /* Card */
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
}

.carddevice {
    width: relative;
    height: 245px;

    /* Brand Color/White */
    background: #FFFFFF;
    box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
}

.custom-shadows {
    box-shadow: 1px 3px 11px -1px rgba(0,0,0,0.2);
    -webkit-box-shadow: 1px 3px 11px -1px rgba(0,0,0,0.2);
    -moz-box-shadow: 1px 3px 11px -1px rgba(0,0,0,0.2);
}

@media (max-width: 800px) {
    .laptop-hide {
        display: none !important;
    }
}

@media (max-width: 799px) {
    .mobile-show {
        display: inline !important;
    }
}

@media (max-width: 348px) {
    .text-mobile {
        font-size: 12px !important;
    }
}

@media (max-width: 334px) {
    .text-mobile {
        font-size: 10px !important;
    }
}